import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoadingSpinner from './components/ui/LoadingSpinner';
import "bootstrap/scss/bootstrap.scss";
import "./assets/scss/main.scss?v=1.3.0";

const Home = React.lazy(() => import('./pages/Home'));
const Apple = React.lazy(() => import('./pages/Apple'));
const Relax = React.lazy(() => import('./pages/Relax'));
const Timer = React.lazy(() => import('./pages/Timer'));
const JsonViewer = React.lazy(() => import('./pages/JsonViewer'));
const JWTDecoder = React.lazy(() => import('./pages/JWTDecoder'));
const Delimiter = React.lazy(() => import('./pages/Delimiter'));
const QuickRefs = React.lazy(() => import('./pages/QuickRefs'));
const QuickRefsHexoCli = React.lazy(() => import('./pages/quick-refs/QuickRefs-HexoCli'));
const SubTitleCleaner = React.lazy(() => import('./pages/SubTitleCleaner'));

function App() {
  return (
    <>
      <Suspense
        fallback={
          <div>
            <LoadingSpinner />
          </div>
        }
      >
        <Switch>
          <Route path='/' exact>
            <Redirect to='/home' />
          </Route>
          <Route path='/home' exact>
            <Home />
          </Route>
          <Route path='/apple' exact>
            <Apple />
          </Route>
          <Route path='/relax' exact>
            <Relax />
          </Route>
          <Route path='/timer' exact>
            <Timer />
          </Route>
          <Route path='/json' exact>
            <JsonViewer />
          </Route>
          <Route path='/jwt' exact>
            <JWTDecoder />
          </Route>
          <Route path='/delimiter' exact>
            <Delimiter />
          </Route>
          <Route path='/quick-refs' exact>
            <QuickRefs />
          </Route>
          <Route path='/quick-refs/hexo-cli' exact>
            <QuickRefsHexoCli />
          </Route>
          <Route path='/subtitle-cleaner' exact>
            <SubTitleCleaner />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
}

export default App;
